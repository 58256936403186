import React from "react";
import "./App.css";
import BussinessButton from "./Components/BussinessButton";
import HomeButton from "./Components/HomeButton";
import logoTipo from "./images/logotipo.png";
import ButtonBackToHome from "./Components/ButtonBackToHome";

function App() {

  // Função para lidar com a prop "business" recebida do BussinessButton
  


  return (
    <div className="App">
      <ButtonBackToHome />
      <div className="wrapper">
          <div className="wrapperWelcomeText">
            <span>Solo tú decides el rumbo de tu negocio</span>
            <br />
            <p>¡Comunícate con nuestros<br></br>videoagentes!</p>
          </div>
          <div>
            <BussinessButton />
          </div>
          <div>
            <HomeButton/>
          </div>
          <div>
            <img src={logoTipo} alt="" />
          </div>
        </div>
    </div>
  );
}

export default App;