import React from 'react';
import imgBackHome from '../images/ayuda.png';
import styles from './ButtonBackToHome.module.css';

function ButtonBackToHome({ onHomeClick }) {
  const handleHomeClick = () => {
    onHomeClick("Home"); // Define a propriedade como "Home" quando o botão for clicado
  };

  return (
    <div>
      <button className={styles.buttonBackToHome} onClick={handleHomeClick}>
        <img src={imgBackHome} alt="" />
        <p>Soporte<br/>24/7</p>
      </button>
    </div>
  )
}

export default ButtonBackToHome;
