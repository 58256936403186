import React from "react";
import homeImg from "../images/home.png";
import styles from "./HomeButton.module.css";
import rHand from "../images/l-hand.png";
import "../App.css";

function HomeButton() {

  const handleOnClick = (e) => {
    e.preventDefault();
    // Enviando un mensaje al componente padre para que cambie la URL del iframe
    window.parent.postMessage({ 
      action: "changeIframeSrc", 
      url: "https://viba720.mobileinsight.com/?qrCodeId=QRC-3986f814-65aa-465b-9997-2a7f6b3da2a9" }, "*");
  };

  return (
    <div className={styles.homeWrapper}>
      <div className={styles.containerHomeImage}>
        <div className={styles.wrapperLHandImage}>
          <img
            className={`${styles.lHandImage} ${styles.shakingHand}`}
            src={rHand}
            alt=""
          />
        </div>
        <div>
        <div onClick={handleOnClick}>
          <img className={styles.homeImg} src={homeImg} alt="Hogar" />
        </div>
        </div>
      </div>

      <div className={styles.containerHomeText}>
        <p>No eres cliente o deseas contratar otro plan de Totalplay.</p>
      </div>


    </div>
  );
}

export default HomeButton;
